import * as React from "react"
import SearchTemplate from "../../templates/search-template";
import { navigate } from "@reach/router";
import { navigate as navigateGatsby } from "gatsby";
import SEO from "../../components/seo";
import _ from "lodash";

class Search extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            searchType: "lettings",
            searchBaseUrl: '/properties-for-rent'
        }
    }

    componentDidMount() {
        if(window)
            window.scroll({
                top: -100,
                behavior: 'smooth'
            });
    }
    changeToMapUrl = () => {
        let pathname = this.props.location.pathname.replace("/properties", "/map-of-properties");
        let url = ""
        if(pathname.includes("page-")) {
            let urls = pathname.split("/");
            urls.pop();
            url = urls.join("/");
        } else {
            url = pathname;
        }
        navigateGatsby(url)
    }
    changeToListUrl = () => {
        let url = this.props.location.pathname.replace("/map-of-properties", "/properties");
        navigateGatsby(url)
    }
    render() {
        var search_area = "Dubai";
        var price_range = "", property_type = "", bedrooms = "";
        var action = "rent";
        if(this.props.params["*"]) {
            search_area = this.props.params["*"].split("/").filter(c => c.includes("in")).length > 0 ? "in "+ this.props.params["*"].split("/").filter(c => c.includes("in"))[0].split("in-")[1].split("-").map(c => _.upperFirst(c)).join(" ") : "in Dubai";
            price_range = this.props.params["*"].split("/").filter(c => c.includes("over-") || c.includes("below-") || c.includes("between-")).length > 0 ? this.props.params["*"].split("/").filter(c => c.includes("over-") || c.includes("below-") || c.includes("between-"))[0].split("-").map(c => isNaN(c) ? c : `AED ${parseInt(c).toLocaleString()}`).join(" ") : "";
            property_type = this.props.params["*"].split("/").filter(c => c.includes("type-")).length > 0 ? this.props.params["*"].split("/").filter(c => c.includes("type-"))[0].split("type-").map(c => c)[1] : "";
            bedrooms = this.props.params["*"].split("/").filter(c => c.includes("-and-more-bedrooms")).length > 0 ? this.props.params["*"].split("/").filter(c => c.includes("-and-more-bedrooms"))[0].split("-and-more-bedrooms")[0] : "";
        }
        search_area = search_area.replace("And", "and");
        if(property_type !== "") {
            if(!["land", "duplex"].includes(property_type)) {
                property_type = property_type+"s"
            }
        }
        return (
            <>
                <SEO
                    title={
                        (price_range !== "" && property_type !== "" && bedrooms !== "") ?
                        `${bedrooms} Bedroom ${property_type} for ${action} ${search_area} ${price_range}` :
                        (property_type !== "" && bedrooms !== "") ?
                        `${bedrooms} Bedroom ${property_type} for ${action} ${search_area}` :
                        (price_range !== "" && bedrooms !== "") ?
                        `${bedrooms} Bedroom for ${action} ${search_area} ${price_range}` :
                        (price_range !== "" && property_type !== "") ?
                        `${_.upperFirst(property_type)} for ${action} ${search_area} ${price_range}` :
                        (bedrooms !== "") ?
                        `${bedrooms} Bedroom for ${action} ${search_area}` :
                        (property_type !== "") ?
                        `${_.upperFirst(property_type)} for ${action} ${search_area}` :
                        (price_range !== "") ?
                        `Properties for ${action} ${search_area} ${price_range}` :
                        `Properties for ${action} ${search_area}`
                    }
                    description={
                        (price_range !== "" && property_type !== "" && bedrooms !== "") ?
                        `Find ${bedrooms} bedroom ${property_type} for ${action} in Dubai ${price_range} with Espace real estate. Contact one of our real estate experts to get assistance in finding the best properties in in Dubai.`:
                        (property_type !== "" && bedrooms !== "") ?
                        `Check out the latest ${bedrooms} bedroom ${property_type} for ${action} in Dubai. Contact Espace real estate agents in Dubai to view the properties` :
                        (price_range !== "" && bedrooms !== "") ?
                        `Explore the list of ${bedrooms} bedroom properties for ${action} in Dubai here. Contact one of our real estate experts today to get assistance in finding the right properties in Dubai`:
                        (price_range !== "" && property_type !== "") ?
                        `Check our latest collection of ${property_type} for ${action} in Dubai ${price_range} with Espace real estate agents in Dubai`:
                        (bedrooms !== "") ?
                        `Explore ${bedrooms} bedroom properties for ${action} in Dubai here. Contact one of our real estate experts today to get assistance in finding the right properties in Dubai.`:
                        (property_type !== "") ?
                        `Looking for ${property_type} for ${action} in Dubai? Espace Real Estate professionals are here to help you. `:
                        (price_range !== "") ?
                        `Find the best properties for ${action} ${search_area} ${price_range} with Espace real estate agents. Conact our properties expert to know more about the properties.` :
                        // `Discover the wide range of Property for Sale ${search_area} ${price_range} with Espace. Book an appointment with one of our specialists today to find the perfect property for sale anywhere in Dubai.`:
                        `Explore our collection of properties ${action} ${search_area}. Contact our real estate professionals to find the best properties for ${action} in Dubai`
                    }
                    location={this.props.location}
                />
                <SearchTemplate
                    {...this.state}
                    // changeStateAndUrl={this.changeStateAndUrl}
                    changeToMapUrl={this.changeToMapUrl}
                    changeToListUrl={this.changeToListUrl}
                    location={this.props.location}
                    mapView={this.props.location.pathname.includes("/properties-map")}
                    layout={``}
                />
            </>
        )
    }
}

export default Search;
